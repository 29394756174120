import * as React from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { Button, Input, Notification, Icon } from "rsuite";
import { v4 as uuid } from "uuid";

import { IAccount, IRequest } from "../../lib/interfaces";
import { InputCurrency } from "../InputCurrency";

interface INewPayment {
  account: IAccount;
  request?: IRequest;
}

export const NewPayment: React.FC<INewPayment> = ({ account, request }) => {
  const [name, setName] = React.useState<string>(request?.name || "");
  const [reference, setReference] = React.useState<string>(
    request?.reference || ""
  );
  const [currency] = React.useState<string>(request?.currency || "CAD");
  const [amount, setAmount] = React.useState<number>(request?.amount || 0);
  const [isFetching, setIsFetching] = React.useState<boolean>(false);
  const [chargeId, setChargeId] = React.useState<string>();
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    try {
      event.preventDefault();
      setIsFetching(true);

      if (amount === 0) {
        Notification.error({ title: "Please enter a dollar amount" });
      }

      if (!stripe || !elements) {
        return;
      }

      const card = elements.getElement(CardElement);

      if (!card) {
        return;
      }

      const { error, token } = await stripe.createToken(card);

      if (error) {
        throw Error(error.message);
      }

      const res = await fetch(
        `${process.env.REACT_APP_WEB_HOST}/.netlify/functions/createStripeCharge`,
        {
          method: "POST",
          body: JSON.stringify({
            name,
            reference,
            amount,
            token,
            currency,
            accountKey: account?.username,
            idempotencyKey: uuid(),
          }),
        }
      );

      const charge = await res.json();

      if (charge.status === "succeeded") {
        setChargeId(charge.id);
      }
    } catch (error) {
      console.log(error);
      Notification.error({ title: "Oops!", description: error.message });
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <div className="container newpayment">
      <div className="newpayment-hero">
        <h1>Hey, {request?.name || "There"}!</h1>
        <p>
          {account.name} has requested a payment from you
          {request?.reference ? ` in reference to ${request.reference}.` : "."}
          <br />
          <br />
          {account?.supportText}
        </p>
      </div>
      <div className="newpayment-card">
        <div className="card">
          <a
            href={account.websiteUrl}
            className="card-logo"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={account?.logo?.uri}
              height={account?.logo?.height}
              width={account?.logo?.width}
              alt={`${account.name}'s logo`}
            />
          </a>
          {chargeId ? (
            <div className="card-body success">
              <Icon icon="check-circle" size="5x" className="success-icon" />
              <span className="success-title">Payment Received</span>
              <span className="success-subtitle">
                Reference ID
                <br />
                {chargeId}
              </span>
            </div>
          ) : (
            <form onSubmit={handleSubmit} className="card-body">
              <InputCurrency
                max={100000000}
                onValueChange={setAmount}
                className="input-amount"
                value={amount}
                currency={currency}
              />
              <Input
                className="input"
                placeholder="Patient Name"
                onChange={setName}
                value={name}
                required
              />
              <Input
                className="input"
                placeholder="Reference (Invoice # or Date)"
                onChange={setReference}
                value={reference}
              />
              <div className="input-stripeParent">
                <CardElement
                  options={{
                    hidePostalCode: true,
                    classes: {
                      base: "input input-stripe",
                    },
                  }}
                />
              </div>
              <Button
                appearance="primary"
                type="submit"
                loading={isFetching}
                block
                style={{
                  backgroundColor: account.color,
                  paddingTop: 15,
                  paddingBottom: 15,
                  fontWeight: 700,
                }}
              >
                Pay With Credit Card
              </Button>
            </form>
          )}
        </div>
        <div className="card-post">Your transaction is secure & encrypted.</div>
      </div>
    </div>
  );
};
