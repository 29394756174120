import { IAccount } from "./interfaces";

const accounts: IAccount[] = [
  {
    name: "Lansdowne Naturopathic Centre",
    websiteUrl: "https://www.lansdownenaturopathic.com",
    username: "lansdowne",
    color: "#69A5B6",
    supportText:
      "If you have any questions, please call or text Angel at +1 (250) 888-3729 or send and email to lansdownenaturopathic@gmail.com",
    logo: {
      uri:
        "https://static1.squarespace.com/static/5c463756b105986dd0830e19/t/5cbf800a1905f4631cf2ea3e/1587428242309/?format=1500w",
      height: "90px",
    },
  },
  {
    name: "BeckerX",
    websiteUrl: "https://beckerx.co",
    username: "beckerx",
    color: "#555555",
    supportText: "",
    logo: {
      uri:
        "https://beckerx-branding.s3.ca-central-1.amazonaws.com/beckerx-logo-dark.eps",
      height: "40px",
    },
  },
];

const getByUsername = (username: string): Promise<IAccount | undefined> => {
  return Promise.resolve(accounts.find((a) => a.username === username));
};

export const Api = {
  accounts: {
    getByUsername,
  },
};
