import React from "react";
import ReactDOM from "react-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import "rsuite/dist/styles/rsuite-default.css";
import "./index.scss";

import * as serviceWorker from "./serviceWorker";
import { Router } from "./Router";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK || "");

ReactDOM.render(
  <React.StrictMode>
    <Elements stripe={stripePromise}>
      <Router />
    </Elements>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
