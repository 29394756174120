import * as React from "react";
import { useParams } from "react-router";
import { Loader, Input, Button, Alert, InputPicker } from "rsuite";
import CopyToClipboard from "react-copy-to-clipboard";

import { IAccount, IRequest } from "../../lib/interfaces";
import { Api } from "../../lib/api";
import {
  InputCurrency,
  getLocaleFromCurrency,
} from "../../components/InputCurrency";

const defaultFormValues: IRequest = {
  name: "",
  reference: "",
  amount: 0,
  currency: "CAD",
};

export const CreateLinkScreen: React.FC = () => {
  const { username } = useParams();
  const [account, setAccount] = React.useState<IAccount>();
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [form, setForm] = React.useState<IRequest>(defaultFormValues);
  const [link, setLink] = React.useState<string>();

  React.useEffect(() => {
    const fetchAccount = async () => {
      setIsLoading(true);

      if (username) {
        const account = await Api.accounts.getByUsername(username);
        setAccount(account);
        setIsLoading(false);
      }
    };

    fetchAccount();
  }, [username]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (form.amount <= 0) {
      Alert.error("You didn't set an amount");
      return;
    }

    const stringified = JSON.stringify(form);
    const encoded = Base64.encodeURI(stringified);
    const nextLink = `${process.env.REACT_APP_WEB_HOST}/${account?.username}/${encoded}`;

    setLink(nextLink);
  };

  const resetForm = () => {
    setForm(defaultFormValues);
    setLink(undefined);
  };

  if (isLoading) {
    return <Loader center />;
  }

  if (account) {
    return (
      <div className="container newpayment">
        <div className="newpayment-hero">
          <h1>Create a Link</h1>
          <p>
            With this form, you can create links that fill out the defaults such
            as names and amounts for customers
          </p>
        </div>
        <div className="newpayment-card">
          <div className="card">
            <a
              href={account.websiteUrl}
              className="card-logo"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={account?.logo?.uri}
                height={account?.logo?.height}
                width={account?.logo?.width}
                alt={`${account.name}'s logo`}
              />
            </a>
            {link ? (
              <div className="card-body">
                <p style={{ fontSize: 16 }}>
                  <strong>{form.name}</strong> &mdash;{" "}
                  {(form.amount / 100).toLocaleString(
                    getLocaleFromCurrency(form.currency),
                    {
                      style: "currency",
                      currency: form.currency,
                    }
                  )}{" "}
                  &mdash; {form.reference}
                </p>
                <div
                  style={{
                    wordBreak: "break-word",
                    marginTop: 30,
                    marginBottom: 40,
                  }}
                >
                  {link}
                </div>
                <CopyToClipboard text={link}>
                  <Button
                    appearance="primary"
                    block
                    style={{
                      backgroundColor: account.color,
                      marginTop: 20,
                      paddingTop: 15,
                      paddingBottom: 15,
                      fontWeight: 700,
                    }}
                  >
                    Copy Link
                  </Button>
                </CopyToClipboard>
                <Button
                  block
                  onClick={resetForm}
                  style={{
                    marginTop: 10,
                    paddingTop: 15,
                    paddingBottom: 15,
                    fontWeight: 700,
                  }}
                >
                  Clear Form
                </Button>
              </div>
            ) : (
              <form onSubmit={handleSubmit} className="card-body">
                <InputCurrency
                  max={100000000}
                  onValueChange={(amount) => setForm({ ...form, amount })}
                  className="input-amount"
                  value={form.amount}
                  currency={form.currency}
                />
                <InputPicker
                  size="lg"
                  placeholder="Currency"
                  className="input input-picker"
                  onSelect={(currency) => setForm({ ...form, currency })}
                  defaultValue="CAD"
                  data={[
                    { value: "CAD", label: "CAD" },
                    { value: "USD", label: "USD" },
                  ]}
                />
                <Input
                  onChange={(name) => setForm({ ...form, name })}
                  value={form.name}
                  placeholder="Customer Name"
                  className="input"
                  required
                />
                <Input
                  onChange={(reference) => setForm({ ...form, reference })}
                  value={form.reference}
                  placeholder="Reference"
                  className="input"
                  required
                />
                <Button
                  type="submit"
                  appearance="primary"
                  block
                  style={{
                    backgroundColor: account.color,
                    paddingTop: 15,
                    paddingBottom: 15,
                    fontWeight: 700,
                  }}
                >
                  Generate Link
                </Button>
              </form>
            )}
          </div>
        </div>
      </div>
    );
  }
  return <p>Something Account Doesn't Exist</p>;
};
