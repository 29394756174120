import { Base64 } from "js-base64";

import { IRequest } from "../lib/interfaces";

export const useRequest = (requestHash?: string): IRequest | undefined => {
  if (!requestHash) {
    return;
  }

  const val = Base64.decode(requestHash);
  const request = JSON.parse(val);

  if (request.name && request.amount && request.currency) {
    return request as IRequest;
  }

  return;
};
