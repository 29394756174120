import * as React from "react";
import { useParams, Redirect } from "react-router-dom";
import { Loader } from "rsuite";

import { NewPayment } from "../../components/NewPayment";
import { IAccount } from "../../lib/interfaces";
import { Api } from "../../lib/api";
import { useRequest } from "../../hooks/useRequest";

export const SendMoneyScreen: React.FC = () => {
  const { username, requestHash } = useParams();
  const [account, setAccount] = React.useState<IAccount>();
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  const request = useRequest(requestHash);

  React.useEffect(() => {
    const fetchAccount = async () => {
      setIsLoading(true);

      if (username) {
        const account = await Api.accounts.getByUsername(username);
        setAccount(account);
        setIsLoading(false);
      }
    };

    fetchAccount();
  }, [username]);

  if (isLoading) {
    return <Loader center />;
  }

  if (requestHash && !request) {
    return <Redirect to={`/${username}`} />;
  }

  if (account) {
    return <NewPayment account={account} request={request} />;
  }

  return <p>Something Went Wrong</p>;
};
