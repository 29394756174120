import * as React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import { SendMoneyScreen } from "./screens/SendMoney";
import { CreateLinkScreen } from "./screens/CreateLinkScreen";

export const Router: React.FC = () => (
  <BrowserRouter>
    <Switch>
      <Redirect exact path="/" to="/lansdowne" />
      <Route exact path="/:username/create" component={CreateLinkScreen} />
      <Route exact path="/:username/:requestHash" component={SendMoneyScreen} />
      <Route exact path="/:username" component={SendMoneyScreen} />
    </Switch>
  </BrowserRouter>
);

// https://ellopay.app/lansdowne/r/98asdf-8asd7fsad-78asdfa8sd0
// https://ellopay.app/r/98asdf-8asd7fsad-78asdfa8sd0
