import React, { CSSProperties, FC, KeyboardEvent, useCallback } from "react";

interface Props {
  className?: string;
  max?: number;
  onValueChange: (value: number) => void;
  style?: CSSProperties;
  value: number;
  currency?: "USD" | "CAD" | string;
  showCurrency?: boolean;
  required?: boolean;
}

export const getLocaleFromCurrency = (currency: string) => {
  switch (currency) {
    case "USD":
      return "en-US";
    case "CAD":
    default:
      return "en-CA";
  }
};

const VALID_FIRST = /^[1-9]{1}$/;
const VALID_NEXT = /^[0-9]{1}$/;
const DELETE_KEY_CODE = 8;

export const InputCurrency: FC<Props> = ({
  className = "",
  max = Number.MAX_SAFE_INTEGER,
  onValueChange,
  style = {},
  value,
  currency = "CAD",
  showCurrency = true,
  required = true,
}) => {
  const valueAbsTrunc = Math.trunc(Math.abs(value));
  if (
    value !== valueAbsTrunc ||
    !Number.isFinite(value) ||
    Number.isNaN(value)
  ) {
    throw new Error(`invalid value property`);
  }
  const handleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLDivElement>): void => {
      const { key, keyCode } = e;
      if (
        (value === 0 && !VALID_FIRST.test(key)) ||
        (value !== 0 && !VALID_NEXT.test(key) && keyCode !== DELETE_KEY_CODE)
      ) {
        return;
      }
      const valueString = value.toString();
      let nextValue: number;
      if (keyCode !== DELETE_KEY_CODE) {
        const nextValueString: string =
          value === 0 ? key : `${valueString}${key}`;
        nextValue = Number.parseInt(nextValueString, 10);
      } else {
        const nextValueString = valueString.slice(0, -1);
        nextValue =
          nextValueString === "" ? 0 : Number.parseInt(nextValueString, 10);
      }
      if (nextValue > max) {
        return;
      }
      onValueChange(nextValue);
    },
    [max, onValueChange, value]
  );
  const handleChange = useCallback(() => {
    // DUMMY TO AVOID REACT WARNING
  }, []);
  const valueDisplay = (value / 100).toLocaleString(
    getLocaleFromCurrency(currency),
    {
      style: "currency",
      currency,
    }
  );

  return (
    <>
      <input
        className={className}
        inputMode="numeric"
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        style={style}
        value={valueDisplay}
        required={required}
      />
      {showCurrency && (
        <span
          style={{
            width: "100%",
            textAlign: "center",
            top: -25,
            position: "relative",
            display: "block",
            opacity: 0.5,
          }}
        >
          {currency}
        </span>
      )}
    </>
  );
};
